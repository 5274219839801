/**** SPACING ****/

// Spacing
$spacing0: 0;
$spacing-px: 1px;
$spacing1: 0.25rem;
$spacing2: 0.5rem;
$spacing3: 0.75rem;
$spacing4: 1rem;
$spacing5: 1.25rem;
$spacing6: 1.5rem;
$spacing8: 2rem;
$spacing10: 2.5rem;
$spacing12: 3rem;
$spacing16: 4rem;
$spacing20: 5rem;
$spacing24: 6rem;
$spacing32: 8rem;
$spacing40: 10rem;
$spacing48: 12rem;
$spacing56: 14rem;
$spacing64: 16rem;

// Screen Breakpoints
$screen-sm: 640px;
$screen-md: 968px; // 768
$screen-lg: 1024px;
$screen-xl: 1280px;

// Max Height
$max-height-full: 100%;
$max-height-screen: 100vh;

// Max Width
$max-width-xs: 20rem;
$max-width-sm: 24rem;
$max-width-md: 28rem;
$max-width-lg: 32rem;
$max-width-xl: 36rem;
$max-width-2xl: 42rem;
$max-width-3xl: 48rem;
$max-width-4xl: 56rem;
$max-width-5xl: 64rem;
$max-width-6xl: 72rem;
$max-width-full: 100%;

// Min Height
$min-height-0: 0;
$min-height-full: 100%;
$min-height-screen: 100vh;

// Min Width
$min-width-0: 0;
$min-width-full: 100%;

// Width
$width-auto: auto;
$width-1_2: 50%;
$width-1_3: 33.333333%;
$width-2_3: 66.666667%;
$width-1_4: 25%;
$width-2_4: 50%;
$width-3_4: 75%;
$width-1_5: 20%;
$width-2_5: 40%;
$width-3_5: 60%;
$width-4_5: 80%;
$width-1_6: 16.666667%;
$width-2_6: 33.333333%;
$width-3_6: 50%;
$width-4_6: 66.666667%;
$width-5_6: 83.333333%;
$width-1_12: 8.333333%;
$width-2_12: 16.666667%;
$width-3_12: 25%;
$width-4_12: 33.333333%;
$width-5_12: 41.666667%;
$width-6_12: 50%;
$width-7_12: 58.333333%;
$width-8_12: 66.666667%;
$width-9_12: 75%;
$width-10_12: 83.333333%;
$width-11_12: 91.666667%;
$width-full: 100%;
$width-screen: 100vw;

// Height
$height-full: 100%;


/**** Containers ****/

// Border Radius
$radius-none: 0;
$radius-sm: .125rem;
$radius-default: .25rem;
$radius-lg: .5rem;
$radius-full: 9999px;

// Border Width
$border-width-default: 1px;
$border-width-0: 0;
$border-width-2: 2px;
$border-width-4: 4px;
$border-width-8: 8px;

// Box Shadow
$box-shadow-default: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
$box-shadow-md: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
$box-shadow-lg: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
$box-shadow-xl: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
$box-shadow-2xl: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
$box-shadow-inner: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06);
$box-shadow-outline: 0 0 0 3px rgba(66, 153, 225, 0.5);
$box-shadow-none: none;


/**** Font ****/

// Size
$font-size-xs: 0.75rem;
$font-size-sm: 0.875rem;
$font-size-base: 1rem;
$font-size-lg: 1.125rem;
$font-size-xl: 1.25rem;
$font-size-2xl: 1.5rem;
$font-size-3xl: 1.875rem;
$font-size-4xl: 2.25rem;
$font-size-5xl: 3rem;
$font-size-6xl: 4rem;

// Weight
$font-weight-hairline: 100;
$font-weight-thin: 200;
$font-weight-light: 300;
$font-weight-normal: 400;
$font-weight-medium: 500;
$font-weight-semibold: 600;
$font-weight-bold: 700;
$font-weight-extrabold: 800;
$font-weight-black: 900;

// Letter Spacing
$letter-spacing-tighter: -0.05em;
$letter-spacing-tight: -0.025em;
$letter-spacing-normal: 0;
$letter-spacing-wide: 0.025em;
$letter-spacing-wider: 0.05em;
$letter-spacing-widest: 0.1em;

// Line Height
$line-height-none: 1;
$line-height-tight: 1.25;
$line-height-snug: 1.375;
$line-height-normal: 1.5;
$line-height-relaxed: 1.625;
$line-height-loose: 2;


/**** UI ****/

// Opacity
$opacity-0: 0;
$opacity-25: 0.25;
$opacity-50: 0.5;
$opacity-75: 0.75;
$opacity-100: 1;

// Z-Index
$z-index-auto: auto;
$z-index-0: 0;
$z-index-10: 10;
$z-index-20: 20;
$z-index-30: 30;
$z-index-40: 40;
$z-index-50: 50;


/**** COLORS ****/

// Black & White
$black: #000000;
$white: #FFFFFF;

// Gray
$gray100: #F7FAFC;
$gray200: #EDF2F7;
$gray300: #E2E8F0;
$gray400: #CBD5E0;
$gray500: #A0AEC0;
$gray600: #718096;
$gray700: #4A5568;
$gray800: #2D3748;
$gray900: #1A202C;

// Red
$red100: #FFF5F5;
$red200: #FED7D7;
$red300: #FEB2B2;
$red400: #FC8181;
$red500: #F56565;
$red600: #E53E3E;
$red700: #C53030;
$red800: #9B2C2C;
$red900: #742A2A;

// Orange
$orange100: #FFFAF0;
$orange200: #FEEBC8;
$orange300: #FBD38D;
$orange400: #F6AD55;
$orange500: #ED8936;
$orange600: #DD6B20;
$orange700: #C05621;
$orange800: #9C4221;
$orange900: #7B341E;

// Yellow
$yellow100: #FFFFF0;
$yellow200: #FEFCBF;
$yellow300: #FAF089;
$yellow400: #F6E05E;
$yellow500: #ECC94B;
$yellow600: #D69E2E;
$yellow700: #B7791F;
$yellow800: #975A16;
$yellow900: #744210;

// Green
$green100: #F0FFF4;
$green200: #C6F6D5;
$green300: #9AE6B4;
$green400: #68D391;
$green500: #48BB78;
$green600: #38A169;
$green700: #2F855A;
$green800: #276749;
$green900: #22543D;

// Teal
$teal100: #E6FFFA;
$teal200: #B2F5EA;
$teal300: #81E6D9;
$teal400: #4FD1C5;
$teal500: #38B2AC;
$teal600: #319795;
$teal700: #2C7A7B;
$teal800: #285E61;
$teal900: #234E52;

// Blue
$blue100: #EBF8FF;
$blue200: #BEE3F8;
$blue300: #90CDF4;
$blue400: #63B3ED;
$blue500: #4299E1;
$blue600: #3182CE;
$blue700: #2B6CB0;
$blue800: #2C5282;
$blue900: #2A4365;

// Indigo
$indigo100: #EBF4FF;
$indigo200: #C3DAFE;
$indigo300: #A3BFFA;
$indigo400: #7F9CF5;
$indigo500: #667EEA;
$indigo600: #5A67D8;
$indigo700: #4C51BF;
$indigo800: #434190;
$indigo900: #3C366B;

// Purple
$purple100: #FAF5FF;
$purple200: #E9D8FD;
$purple300: #D6BCFA;
$purple400: #B794F4;
$purple500: #9F7AEA;
$purple600: #805AD5;
$purple700: #6B46C1;
$purple800: #553C9A;
$purple900: #44337A;

// Pink
$pink100: #FFF5F7;
$pink200: #FED7E2;
$pink300: #FBB6CE;
$pink400: #F687B3;
$pink500: #ED64A6;
$pink600: #D53F8C;
$pink700: #B83280;
$pink800: #97266D;
$pink900: #702459;


/**** JS EXPORTS ****/

:export {
  screenSm: $screen-sm;
  screenMd: $screen-md;
  screenLg: $screen-lg;
  screenXl: $screen-xl;
}
